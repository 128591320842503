import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { withCoreComponent, withLocale } from 'core/hocs';
import { UpcomingGames as UpcomingGamesCore } from 'core/components';
import { UPCOMING_GAMES_TYPES, UPCOMING_GAMES_TIME_FILTERS, AI_OUTCOME_SECTION } from 'core/constants';
import { isEmptyOrNil } from 'core/helpers';
import store from 'store';

import { GA } from 'helpers/ga';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { GamesTable } from 'components/games-table/games-table';
import { GamesTablePreloader } from 'components/games-table/games-table-preloader/games-table-preloader';
import { NoBetsStub } from 'components/no-bets-stub/no-bets-stub';
import { groupUpcomingGamesByDate } from 'helpers/games';
import { TimeFilters } from './time-filters/time-filters';
import { PAGE_NAMES } from '../../constants';

const STORAGE_UPCOMING_IDS = 'closedUpcomingIds';

class UpcomingGamesUI extends Component {
  static propTypes = {
    type: PropTypes.oneOf([
      UPCOMING_GAMES_TYPES.ALL,
      UPCOMING_GAMES_TYPES.SPORT,
      UPCOMING_GAMES_TYPES.LEAGUE,
    ]).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()),
    isInProgress: PropTypes.bool.isRequired,
    timeFilter: PropTypes.number.isRequired,
    setUpcomingGamesTimeFilter: PropTypes.func.isRequired,
    className: PropTypes.string,
    locale: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
    match: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    items: null,
    className: null,
  };

  state = {
    closedIds: this.props.pageName === PAGE_NAMES.LEAGUE ? [] : (store.get(STORAGE_UPCOMING_IDS) || []),
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: {
          leagueId: prevLeagueId,
        }
      }
    } = prevProps;
    const {
      match: {
        params: {
          leagueId,
        }
      }
    } = this.props;

    if (prevLeagueId !== leagueId) {
      this.clearClosedIdsInState();
    }
  }

  clearClosedIdsInState = () => this.setState({ closedIds: [] });

  toggleOpenTable = (e) => {
    const { dataset: { tableId } } = e.currentTarget;
    const { closedIds } = this.state;
    const { pageName } = this.props;
    let label;
    let isExpanded;
    let currentClosedIds = closedIds;

    if (closedIds.length) {
      const isExistedId = closedIds.includes(tableId);

      if (isExistedId) {
        isExpanded = true;
        currentClosedIds = closedIds.filter(id => id !== tableId);
      } else {
        currentClosedIds = [...closedIds, tableId];
      }
    } else {
      currentClosedIds = [tableId];
    }

    this.setState({ closedIds: currentClosedIds });

    if (pageName !== PAGE_NAMES.LEAGUE) {
      store.set(STORAGE_UPCOMING_IDS, currentClosedIds);
    }

    if (pageName === PAGE_NAMES.HOME) {
      label = isExpanded ? 'main-upcoming-expand' : 'main-upcoming-collapse';
    } else if (pageName === PAGE_NAMES.SPORT) {
      label = isExpanded ? 'sport-upcoming-expand' : 'sport-upcoming-collapse';
    } else if (pageName === PAGE_NAMES.LEAGUE) {
      label = isExpanded ? 'league-upcoming-expand' : 'league-upcoming-collapse';
    }

    if (label) {
      GA.event({
        category: 'expand-collapse',
        label,
      });
    }
  }

  render() {
    const {
      items,
      locale,
      pageName,
      timeFilter,
      className,
      setUpcomingGamesTimeFilter,
      type,
      isInProgress,
    } = this.props;
    const { closedIds } = this.state;
    let formattedGames = items;
    const isLeagueType = type === UPCOMING_GAMES_TYPES.LEAGUE;
    let sectionBeforeEventPage = null;

    if (isEmptyOrNil(formattedGames) && !isInProgress && timeFilter === UPCOMING_GAMES_TIME_FILTERS.ALL_TIME) {
      return null;
    }

    if (isLeagueType && !isEmptyOrNil(formattedGames)) {
      const grouppedGames = groupUpcomingGamesByDate(formattedGames[0].games, locale);

      formattedGames = R.map(groupDate => ({
        groupDate,
        sportId: formattedGames[0].sportId,
        leagueId: formattedGames[0].leagueId,
        games: grouppedGames[groupDate],
      }), Object.keys(grouppedGames));
    }

    if (pageName === PAGE_NAMES.HOME) {
      sectionBeforeEventPage = AI_OUTCOME_SECTION.MAIN_UPCOMING;
    } else if (pageName === PAGE_NAMES.SPORT) {
      sectionBeforeEventPage = AI_OUTCOME_SECTION.SPORT_UPCOMING;
    } else if (isLeagueType) {
      sectionBeforeEventPage = AI_OUTCOME_SECTION.LEAGUE_UPCOMING;
    }

    return (
      <div className={className}>
        <FormattedTag id={`general.${isLeagueType ? 'upcoming' : 'top-upcoming'}`} className="section-title d-block font-weight-bold mb-1 pl-2 pl-sm-0" />

        {!isLeagueType && (
          <TimeFilters
            isInProgress={isInProgress}
            timeFilter={timeFilter}
            setUpcomingGamesTimeFilter={setUpcomingGamesTimeFilter}
          />
        )}

        {isInProgress
          ? <GamesTablePreloader withHeader={false} />
          : (
            <Fragment>
              {formattedGames && !!formattedGames.length
                ? (
                  <Fragment>
                    {type === UPCOMING_GAMES_TYPES.SPORT && <div id="health-check-2" />}
                    {formattedGames.map((game) => {
                      const tableId = isLeagueType ? game.groupDate : `${game.sportId}-${game.leagueId}`;
                      const isClose = closedIds.includes(tableId);

                      return (
                        <GamesTable
                          key={tableId}
                          tableId={tableId}
                          {...game}
                          isLeagueType={isLeagueType}
                          pageName={pageName}
                          toggleOpenTable={this.toggleOpenTable}
                          isOpen={!isClose}
                          sectionBeforeEventPage={sectionBeforeEventPage}
                        />
                      );
                    })}
                  </Fragment>
                )
                : <NoBetsStub />}
            </Fragment>
          )}
      </div>
    );
  }
}

export const UpcomingGames = withRouter(withCoreComponent(UpcomingGamesCore, withLocale(UpcomingGamesUI)));
