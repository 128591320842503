import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { withLocale } from 'core/hocs';

import { RouteLink } from 'components/route-link/route-link';
import { IconNoBets } from 'components/icons/icon-no-bets/icon-no-bets';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';

import './no-bets-stub.scss';

export const NoBetsStubUI = (
  {
    isShowed,
    className,
    message,
    subMessage,
    locale,
    isButtonHide,
  }
) => (
  <div className={classNames(className, 'no-bets-container flex-column align-items-center mb-2 pt-7 mt-4', {
    'd-none': !isShowed,
    'd-flex': isShowed,
  })}
  >
    <IconNoBets />
    <div className="mt-2_5 mb-4 text-extra-3 px-2 px-sm-0 text-center">
      <FormattedTag tag="div" id={message} />
      {subMessage && <FormattedTag tag="div" id={subMessage} />}
    </div>
    {!isButtonHide && (
      <RouteLink
        locale={locale}
        to="/"
        className="d-flex align-items-center justify-content-center no-bets-btn font-weight-bold rounded px-2 py-1 text-small"
      >
        <FormattedMessage id="general.go-to-homepage" />
      </RouteLink>
    )}
  </div>
);

NoBetsStubUI.propTypes = {
  locale: PropTypes.string.isRequired,
  className: PropTypes.string,
  isShowed: PropTypes.bool,
  message: PropTypes.string,
  subMessage: PropTypes.string,
  isButtonHide: PropTypes.bool,
};

NoBetsStubUI.defaultProps = {
  className: null,
  isShowed: true,
  message: 'general.no-bets',
  subMessage: null,
  isButtonHide: false,
};

export const NoBetsStub = withLocale(NoBetsStubUI);
