import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { UPCOMING_GAMES_TIME_FILTERS } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { GA } from 'helpers/ga';

import './time-filters-mobile.scss';

const TIME_FILTERS = [
  {
    intl: 'time-filter.all-time',
    filter: UPCOMING_GAMES_TIME_FILTERS.ALL_TIME,
    ga: 'all-time',
  },
  {
    intl: 'time-filter.today',
    filter: UPCOMING_GAMES_TIME_FILTERS.TODAY,
    ga: '24-hours',
  },
  {
    intl: 'time-filter.12-hours',
    filter: UPCOMING_GAMES_TIME_FILTERS.HOURS_12,
    ga: '12-hours',
  },
  {
    intl: 'time-filter.6-hours',
    filter: UPCOMING_GAMES_TIME_FILTERS.HOURS_6,
    ga: '6-hours',
  },
  {
    intl: 'time-filter.1-hour',
    filter: UPCOMING_GAMES_TIME_FILTERS.HOURS_1,
    ga: '1-hour',
  },
];

class TimeFiltersMobileUI extends Component {
  static propTypes = {
    isInProgress: PropTypes.bool.isRequired,
    timeFilter: PropTypes.number.isRequired,
    setUpcomingGamesTimeFilter: PropTypes.func.isRequired,
    match: PropTypes.shape().isRequired,
  };

  timeFiltersRef = createRef();

  state = {
    isLeftGradientShowed: false,
    isRightGradientShowed: false,
  };

  componentDidMount() {
    if (this.timeFiltersRef && this.timeFiltersRef.current) {
      const { scrollWidth } = this.timeFiltersRef.current;

      if (scrollWidth > window.innerWidth) {
        this.setState({ isRightGradientShowed: true });
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { sportId } } } = this.props;
    const { match: { params: { sportId: prevSportId } } } = prevProps;

    if (sportId !== prevSportId) {
      if (this.timeFiltersRef && this.timeFiltersRef.current) {
        this.timeFiltersRef.current.scrollLeft = 0;
      }
    }
  }

  onTimeFilterClick = (e) => {
    const { dataset: { filter, ga } } = e.currentTarget;
    const { isInProgress, setUpcomingGamesTimeFilter } = this.props;

    if (isInProgress) {
      return;
    }

    setUpcomingGamesTimeFilter(Number(filter));
    GA.event({
      category: 'top-upcoming',
      label: ga,
    });
  };

  onScroll = () => {
    const { isLeftGradientShowed, isRightGradientShowed } = this.state;

    if (this.timeFiltersRef && this.timeFiltersRef.current) {
      const { scrollWidth, scrollLeft } = this.timeFiltersRef.current;

      if (isLeftGradientShowed) {
        if (scrollLeft === 0) {
          this.setState({ isLeftGradientShowed: false });
        }
      } else if (scrollLeft > 0) {
        this.setState({ isLeftGradientShowed: true });
      }

      if (isRightGradientShowed) {
        if (scrollLeft + window.innerWidth >= scrollWidth) {
          this.setState({ isRightGradientShowed: false });
        }
      } else if (scrollLeft + window.innerWidth < scrollWidth) {
        this.setState({ isRightGradientShowed: true });
      }
    }
  };

  render() {
    const { timeFilter } = this.props;
    const { isLeftGradientShowed, isRightGradientShowed } = this.state;

    return (
      <div className="position-relative mb-1_5">
        <div
          ref={this.timeFiltersRef}
          onScroll={this.onScroll}
          className={classNames('time-filters-mobile d-flex align-items-center bg-main-2 rounded text-small text-extra-2', {
            'is-left-gradient-showed': isLeftGradientShowed,
            'is-right-gradient-showed': isRightGradientShowed,
          })}
        >
          {TIME_FILTERS.map(({ filter, intl, ga }) => (
            <FormattedTag
              id={intl}
              role="button"
              tabIndex="0"
              onClick={this.onTimeFilterClick}
              onKeyPress={this.onTimeFilterClick}
              data-filter={filter}
              data-ga={ga}
              className={classNames('time-filters-mobile-item position-relative d-flex align-items-center justify-content-center px-2_5 text-nowrap', {
                'text-extra-1 font-weight-bold': timeFilter === filter,
              })}
            />
          ))}
        </div>
      </div>
    );
  }
}

export const TimeFiltersMobile = withRouter(TimeFiltersMobileUI);
