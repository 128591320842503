import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { UPCOMING_GAMES_TIME_FILTERS } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { GA } from 'helpers/ga';

import './time-filters-desktop.scss';

const TIME_FILTERS = [
  {
    intl: 'time-filter.all-time',
    filter: UPCOMING_GAMES_TIME_FILTERS.ALL_TIME,
    ga: 'all-time',
  },
  {
    intl: 'time-filter.today',
    filter: UPCOMING_GAMES_TIME_FILTERS.TODAY,
    ga: '24-hours',
  },
  {
    intl: 'time-filter.12-hours',
    filter: UPCOMING_GAMES_TIME_FILTERS.HOURS_12,
    ga: '12-hours',
  },
  {
    intl: 'time-filter.6-hours',
    filter: UPCOMING_GAMES_TIME_FILTERS.HOURS_6,
    ga: '6-hours',
  },
  {
    intl: 'time-filter.1-hour',
    filter: UPCOMING_GAMES_TIME_FILTERS.HOURS_1,
    ga: '1-hour',
  },
];

export class TimeFiltersDesktop extends Component {
  static propTypes = {
    isInProgress: PropTypes.bool.isRequired,
    timeFilter: PropTypes.number.isRequired,
    setUpcomingGamesTimeFilter: PropTypes.func.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props, nextProps);
  }

  onTimeFilterClick = (e) => {
    const { dataset: { filter, ga } } = e.currentTarget;
    const { isInProgress, setUpcomingGamesTimeFilter } = this.props;

    if (isInProgress) {
      return;
    }

    setUpcomingGamesTimeFilter(Number(filter));
    GA.event({
      category: 'top-upcoming',
      label: ga,
    });
  };

  render() {
    const { timeFilter } = this.props;

    return (
      <div className="time-filters-desktop d-flex align-items-center mb-1_5 bg-main-2 rounded text-small text-extra-2">
        {TIME_FILTERS.map(({ filter, intl, ga }) => (
          <FormattedTag
            id={intl}
            role="button"
            tabIndex="0"
            onClick={this.onTimeFilterClick}
            onKeyPress={this.onTimeFilterClick}
            data-filter={filter}
            data-ga={ga}
            className={classNames('time-filters-desktop-item position-relative d-flex align-items-center justify-content-center', {
              'text-extra-1 font-weight-bold': timeFilter === filter,
            })}
          />
        ))}
      </div>
    );
  }
}
