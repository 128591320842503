import React from 'react';

import { MediaQuery } from 'components/media-query/media-query';
import { TimeFiltersMobile } from './time-filters-mobile/time-filters-mobile';
import { TimeFiltersDesktop } from './time-filters-desktop/time-filters-desktop';

export const TimeFilters = props => (
  <div>
    <MediaQuery down="xs">
      <TimeFiltersMobile {...props} />
    </MediaQuery>
    <MediaQuery up="sm">
      <TimeFiltersDesktop {...props} />
    </MediaQuery>
  </div>
);
