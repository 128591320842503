import React from 'react';
import './icon-no-bets.scss';

export const IconNoBets = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="259" height="150" viewBox="0 0 259 150">
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path fill="url(#icon-no-bets-a)" d="M108.54 101.636L3.934 63.67C.83 62.544-.768 59.124.36 56.031L19.27 4.225C20.4 1.132 23.829-.462 26.93.663L131.54 38.63c3.102 1.126 4.7 4.546 3.572 7.639l-18.91 51.806c-1.129 3.093-4.558 4.687-7.66 3.56z" transform="translate(122.529 33.78)" />
        <path fill="url(#icon-no-bets-b)" d="M108.54 101.636L3.934 63.67C.83 62.544-.768 59.124.36 56.031L19.27 4.225C20.4 1.132 23.829-.462 26.93.663L131.54 38.63c3.102 1.126 4.7 4.546 3.572 7.639l-18.91 51.806c-1.129 3.093-4.558 4.687-7.66 3.56z" transform="translate(122.529 33.78)" />
        <path fill="#FFF" d="M77.698 36.928L26.095 18.2c-1.162-.422-1.763-1.704-1.34-2.865.423-1.16 1.71-1.758 2.873-1.337l51.603 18.728c1.162.422 1.763 1.705 1.339 2.865-.423 1.16-1.71 1.759-2.872 1.337z" opacity=".2" transform="translate(122.529 33.78)" />
        <path fill="#FFF" d="M96.199 40.442c1.48-.385 4.421-.848 7.633.32 3.211 1.17 5.167 3.415 6.053 4.662l-2.886 7.928c-1.056 2.9-6.535 4.04-9.319 4.327-.63-.65-1.637-1.766-2.562-3.12-1.208-1.767-2.501-4.275-1.805-6.189l2.886-7.928z" opacity=".05" transform="translate(122.529 33.78)" />
        <path fill="#FFF" d="M40.379 66.195L11.594 55.748c-.776-.281-1.176-1.137-.894-1.91l5.11-14.002c.283-.773 1.14-1.172 1.916-.89l28.785 10.446c.776.281 1.176 1.137.894 1.91l-5.11 14.002c-.283.774-1.14 1.172-1.916.89zM74.079 78.426L45.293 67.98c-.775-.282-1.176-1.137-.893-1.91l5.11-14.002c.283-.773 1.14-1.173 1.916-.891L80.21 61.623c.776.282 1.176 1.137.894 1.91l-5.11 14.002c-.283.773-1.14 1.173-1.916.89z" opacity=".1" transform="translate(122.529 33.78)" />
        <path fill="#FFF" d="M107.779 90.656L78.993 80.209c-.775-.281-1.176-1.136-.893-1.91l5.11-14.001c.283-.774 1.14-1.173 1.916-.891l28.785 10.446c.776.282 1.176 1.137.894 1.91l-5.11 14.002c-.285.775-1.14 1.172-1.916.89z" opacity=".05" transform="translate(122.529 33.78)" />
        <path fill="#FFF" d="M52.133 38.352l-29.488-10.7c-1.162-.422-1.763-1.705-1.34-2.866.423-1.16 1.71-1.758 2.873-1.337l29.488 10.702c1.162.421 1.763 1.704 1.34 2.865-.424 1.16-1.71 1.758-2.873 1.336z" opacity=".2" transform="translate(122.529 33.78)" />
      </g>
      <g fillRule="nonzero">
        <path fill="url(#icon-no-bets-c)" d="M131.539 63.67L26.93 101.636c-3.102 1.126-6.532-.47-7.661-3.563L.36 46.27c-1.13-3.093.47-6.513 3.573-7.64L108.54.664c3.102-1.127 6.531.47 7.66 3.562l18.91 51.806c1.129 3.093-.47 6.513-3.572 7.64z" transform="translate(0 33.78)" />
        <path fill="#FFF" d="M39.837 40.442c-1.48-.385-4.422-.848-7.633.32-3.211 1.17-5.167 3.415-6.053 4.662l2.885 7.928c1.056 2.9 6.536 4.04 9.32 4.327.63-.65 1.637-1.766 2.562-3.12 1.208-1.767 2.501-4.275 1.805-6.189l-2.886-7.928zM56.478 80.21L27.693 90.655c-.776.281-1.634-.118-1.916-.891l-5.11-14c-.283-.774.118-1.629.893-1.91l28.786-10.447c.775-.281 1.633.118 1.915.891l5.11 14.002c.283.772-.118 1.627-.893 1.908z" opacity=".06" transform="translate(0 33.78)" />
        <path fill="#FFF" d="M90.178 67.978L61.393 78.424c-.776.282-1.634-.117-1.916-.89l-5.11-14.002c-.283-.773.118-1.629.893-1.91l28.785-10.447c.776-.281 1.634.118 1.916.891l5.11 14.002c.281.775-.118 1.63-.893 1.91zM123.876 55.748L95.091 66.195c-.776.281-1.633-.118-1.916-.891l-5.11-14.002c-.282-.773.118-1.629.894-1.91l28.785-10.447c.775-.281 1.633.118 1.915.891l5.11 14.002c.283.773-.117 1.629-.893 1.91z" opacity=".05" transform="translate(0 33.78)" />
      </g>
      <g fillRule="nonzero">
        <g fill="url(#icon-no-bets-d)" transform="translate(25.897 8.94)">
          <path d="M27.23 42.167c-.898.228-1.8.395-2.696.504-.445.053-.889.092-1.333.12-4.234.246-8.35-.782-11.882-2.813-.347-.2-.689-.408-1.024-.627-4.321-2.816-7.638-7.197-9.008-12.582-.395-1.548-.607-3.1-.652-4.632-.054-1.793.123-3.558.508-5.263C2.261 11.92 5.137 7.483 9.25 4.437c2.173-1.61 4.69-2.832 7.48-3.537 1.886-.477 3.777-.688 5.632-.657 3.404.06 6.69.933 9.612 2.48 3.976 2.1 7.277 5.442 9.283 9.654.59 1.236 1.067 2.547 1.417 3.922.792 3.11.852 6.231.29 9.187 0 .002-.002.003 0 .005-.123.644-.275 1.28-.456 1.907-.258.9-.577 1.78-.952 2.634-.774 1.766-1.782 3.423-2.999 4.922-2.797 3.436-6.69 6.039-11.326 7.213z" />
          <path d="M27.23 42.167c-.898.228-1.8.395-2.696.504-.445.053-.889.092-1.333.12-4.234.246-8.35-.782-11.882-2.813-.347-.2-.689-.408-1.024-.627-4.321-2.816-7.638-7.197-9.008-12.582-.395-1.548-.607-3.1-.652-4.632-.054-1.793.123-3.558.508-5.263C2.261 11.92 5.137 7.483 9.25 4.437c2.173-1.61 4.69-2.832 7.48-3.537 1.886-.477 3.777-.688 5.632-.657 3.404.06 6.69.933 9.612 2.48 3.976 2.1 7.277 5.442 9.283 9.654.59 1.236 1.067 2.547 1.417 3.922.792 3.11.852 6.231.29 9.187 0 .002-.002.003 0 .005-.123.644-.275 1.28-.456 1.907-.258.9-.577 1.78-.952 2.634-.774 1.766-1.782 3.423-2.999 4.922-2.797 3.436-6.69 6.039-11.326 7.213z" />
        </g>
        <g fill="url(#icon-no-bets-e)" transform="translate(25.897 8.94)">
          <path d="M17.817.648C13.23 1.551 9.17 3.92 6.15 7.248v.443c.681-.555 1.654-1.229 2.923-1.78.972-.425 1.866-.666 2.597-.81.724-.726 1.74-1.61 3.084-2.428 1.34-.816 2.594-1.311 3.571-1.618-.169-.136-.338-.273-.507-.407zM2.74 24.85c.014-1.396.147-2.649.325-3.723-.368-.584-.858-1.517-1.137-2.752-.274-1.206-.238-2.235-.163-2.915l-.39.467C.888 17.712.628 19.592.628 21.53c0 2.868.567 5.602 1.598 8.1.417.23.825.242.999.238-.277-1.367-.502-3.065-.485-5.019zM19.3 36.83c-1.716-.902-3.119-1.876-4.221-2.752-.72.387-1.589.788-2.598 1.134-1.346.46-2.571.691-3.57.809.204.466.516 1.09.973 1.78.707 1.064 1.408 1.745 1.949 2.267.15.144.314.296.493.456 1.562.79 3.235 1.397 4.99 1.785.533.023 1.087.034 1.66.023.924-.015 1.794-.074 2.597-.162.337-.252.868-.718 1.298-1.457.5-.858.617-1.666.65-2.104-1.24-.4-2.68-.969-4.221-1.78zm21.917-13.112c-.702-1.46-1.616-2.466-2.272-3.076-.848.854-1.922 1.814-3.247 2.752-1.287.912-2.51 1.595-3.572 2.104.151 1.239.241 2.714.163 4.372-.083 1.813-.348 3.396-.65 4.695.768.171 1.762.323 2.923.323.788 0 1.789-.07 2.923-.323.87-.906 1.97-2.244 2.923-4.047.88-1.666 1.355-3.202 1.624-4.371-.138-.675-.377-1.519-.815-2.43zm-17.696-7.61c-.244-1.71-.596-3.231-.974-4.532-1.191-.426-2.666-.851-4.384-1.134-1.73-.283-3.273-.35-4.546-.323-.868 1.008-1.842 2.297-2.76 3.886-1 1.734-1.664 3.352-2.11 4.694.664 1.078 1.61 2.39 2.923 3.724 1.273 1.293 2.531 2.24 3.571 2.914 1.462-.505 3.116-1.183 4.87-2.103 1.42-.745 2.663-1.522 3.734-2.267.01-1.415-.067-3.053-.324-4.859zm15.58-7.298c-1.159-1.545-2.52-2.926-4.047-4.11-.738-.571-1.515-1.096-2.327-1.567-.565-.329-1.146-.633-1.742-.91l-.005-.001c-.51-.154-1.105-.288-1.778-.361-.817-.09-1.536-.061-2.11 0 .484.511 1.094 1.26 1.623 2.266.56 1.064.832 2.042.974 2.752 1.369.593 2.92 1.38 4.546 2.429 1.176.757 2.2 1.53 3.084 2.266.294-.174.868-.567 1.298-1.294.346-.585.45-1.134.488-1.458L39.1 8.81z" />
          <path d="M17.817.648C13.23 1.551 9.17 3.92 6.15 7.248v.443c.681-.555 1.654-1.229 2.923-1.78.972-.425 1.866-.666 2.597-.81.724-.726 1.74-1.61 3.084-2.428 1.34-.816 2.594-1.311 3.571-1.618-.169-.136-.338-.273-.507-.407zM2.74 24.85c.014-1.396.147-2.649.325-3.723-.368-.584-.858-1.517-1.137-2.752-.274-1.206-.238-2.235-.163-2.915l-.39.467C.888 17.712.628 19.592.628 21.53c0 2.868.567 5.602 1.598 8.1.417.23.825.242.999.238-.277-1.367-.502-3.065-.485-5.019zM19.3 36.83c-1.716-.902-3.119-1.876-4.221-2.752-.72.387-1.589.788-2.598 1.134-1.346.46-2.571.691-3.57.809.204.466.516 1.09.973 1.78.707 1.064 1.408 1.745 1.949 2.267.15.144.314.296.493.456 1.562.79 3.235 1.397 4.99 1.785.533.023 1.087.034 1.66.023.924-.015 1.794-.074 2.597-.162.337-.252.868-.718 1.298-1.457.5-.858.617-1.666.65-2.104-1.24-.4-2.68-.969-4.221-1.78zm21.917-13.112c-.702-1.46-1.616-2.466-2.272-3.076-.848.854-1.922 1.814-3.247 2.752-1.287.912-2.51 1.595-3.572 2.104.151 1.239.241 2.714.163 4.372-.083 1.813-.348 3.396-.65 4.695.768.171 1.762.323 2.923.323.788 0 1.789-.07 2.923-.323.87-.906 1.97-2.244 2.923-4.047.88-1.666 1.355-3.202 1.624-4.371-.138-.675-.377-1.519-.815-2.43zm-17.696-7.61c-.244-1.71-.596-3.231-.974-4.532-1.191-.426-2.666-.851-4.384-1.134-1.73-.283-3.273-.35-4.546-.323-.868 1.008-1.842 2.297-2.76 3.886-1 1.734-1.664 3.352-2.11 4.694.664 1.078 1.61 2.39 2.923 3.724 1.273 1.293 2.531 2.24 3.571 2.914 1.462-.505 3.116-1.183 4.87-2.103 1.42-.745 2.663-1.522 3.734-2.267.01-1.415-.067-3.053-.324-4.859zm15.58-7.298c-1.159-1.545-2.52-2.926-4.047-4.11-.738-.571-1.515-1.096-2.327-1.567-.565-.329-1.146-.633-1.742-.91l-.005-.001c-.51-.154-1.105-.288-1.778-.361-.817-.09-1.536-.061-2.11 0 .484.511 1.094 1.26 1.623 2.266.56 1.064.832 2.042.974 2.752 1.369.593 2.92 1.38 4.546 2.429 1.176.757 2.2 1.53 3.084 2.266.294-.174.868-.567 1.298-1.294.346-.585.45-1.134.488-1.458L39.1 8.81z" />
        </g>
        <g>
          <path fill="url(#icon-no-bets-f)" d="M27.23 42.167c-.898.228-1.8.395-2.696.504-.445.053-.889.092-1.333.12-4.234.246-8.35-.782-11.882-2.813-.347-.2-.689-.408-1.024-.627-4.321-2.816-7.638-7.197-9.008-12.582-.395-1.548-.607-3.1-.652-4.632-.054-1.793.123-3.558.508-5.263C2.261 11.92 5.137 7.483 9.25 4.437c2.173-1.61 4.69-2.832 7.48-3.537 1.886-.477 3.777-.688 5.632-.657 3.404.06 6.69.933 9.612 2.48 3.976 2.1 7.277 5.442 9.283 9.654.59 1.236 1.067 2.547 1.417 3.922.792 3.11.852 6.231.29 9.187 0 .002-.002.003 0 .005-.123.644-.275 1.28-.456 1.907-.258.9-.577 1.78-.952 2.634-.774 1.766-1.782 3.423-2.999 4.922-2.797 3.436-6.69 6.039-11.326 7.213z" transform="translate(25.897 8.94)" />
          <path fill="#000" d="M17.817.648C13.23 1.551 9.17 3.92 6.15 7.248v.443c.681-.555 1.654-1.229 2.923-1.78.972-.425 1.866-.666 2.597-.81.724-.726 1.74-1.61 3.084-2.428 1.34-.816 2.594-1.311 3.571-1.618-.169-.136-.338-.273-.507-.407zM2.74 24.85c.014-1.396.147-2.649.325-3.723-.368-.584-.858-1.517-1.137-2.752-.274-1.206-.238-2.235-.163-2.915l-.39.467C.888 17.712.628 19.592.628 21.53c0 2.868.567 5.602 1.598 8.1.417.23.825.242.999.238-.277-1.367-.502-3.065-.485-5.019zM19.3 36.83c-1.716-.902-3.119-1.876-4.221-2.752-.72.387-1.589.788-2.598 1.134-1.346.46-2.571.691-3.57.809.204.466.516 1.09.973 1.78.707 1.064 1.408 1.745 1.949 2.267.15.144.314.296.493.456 1.562.79 3.235 1.397 4.99 1.785.533.023 1.087.034 1.66.023.924-.015 1.794-.074 2.597-.162.337-.252.868-.718 1.298-1.457.5-.858.617-1.666.65-2.104-1.24-.4-2.68-.969-4.221-1.78zm21.917-13.112c-.702-1.46-1.616-2.466-2.272-3.076-.848.854-1.922 1.814-3.247 2.752-1.287.912-2.51 1.595-3.572 2.104.151 1.239.241 2.714.163 4.372-.083 1.813-.348 3.396-.65 4.695.768.171 1.762.323 2.923.323.788 0 1.789-.07 2.923-.323.87-.906 1.97-2.244 2.923-4.047.88-1.666 1.355-3.202 1.624-4.371-.138-.675-.377-1.519-.815-2.43zm-17.696-7.61c-.244-1.71-.596-3.231-.974-4.532-1.191-.426-2.666-.851-4.384-1.134-1.73-.283-3.273-.35-4.546-.323-.868 1.008-1.842 2.297-2.76 3.886-1 1.734-1.664 3.352-2.11 4.694.664 1.078 1.61 2.39 2.923 3.724 1.273 1.293 2.531 2.24 3.571 2.914 1.462-.505 3.116-1.183 4.87-2.103 1.42-.745 2.663-1.522 3.734-2.267.01-1.415-.067-3.053-.324-4.859zm15.58-7.298c-1.159-1.545-2.52-2.926-4.047-4.11-.738-.571-1.515-1.096-2.327-1.567-.565-.329-1.146-.633-1.742-.91l-.005-.001c-.51-.154-1.105-.288-1.778-.361-.817-.09-1.536-.061-2.11 0 .484.511 1.094 1.26 1.623 2.266.56 1.064.832 2.042.974 2.752 1.369.593 2.92 1.38 4.546 2.429 1.176.757 2.2 1.53 3.084 2.266.294-.174.868-.567 1.298-1.294.346-.585.45-1.134.488-1.458L39.1 8.81z" opacity=".65" transform="translate(25.897 8.94)" />
        </g>
      </g>
      <g>
        <path fill="url(#icon-no-bets-g)" fillRule="nonzero" d="M158.39 89.4H5.978C2.677 89.4 0 86.731 0 83.44V5.96C0 2.669 2.676 0 5.977 0h152.414c3.3 0 5.977 2.669 5.977 5.96v77.48c0 3.291-2.676 5.96-5.977 5.96z" transform="translate(46.816 28.31)" />
        <path fill="#FFF" fillOpacity=".12" d="M51.603 17.77c-1.678-1.159-5.153-3.08-9.71-3.08-4.556 0-8.03 1.921-9.71 3.08v11.249c0 4.116 6.347 8.041 9.704 9.671 1.085-.527 2.857-1.466 4.632-2.74 2.32-1.664 5.084-4.216 5.084-6.931v-11.25zM131.603 17.77c-1.678-1.159-5.153-3.08-9.71-3.08-4.556 0-8.03 1.921-9.71 3.08v11.249c0 4.116 6.347 8.041 9.704 9.671 1.085-.527 2.857-1.466 4.632-2.74 2.32-1.664 5.084-4.216 5.084-6.931v-11.25zM94.575 31.65H69.172c-1.65 0-2.988-1.334-2.988-2.98s1.337-2.98 2.988-2.98h25.403c1.65 0 2.988 1.334 2.988 2.98s-1.337 2.98-2.988 2.98z" transform="translate(46.816 28.31)" />
        <path fill="url(#icon-no-bets-h)" d="M55.287 52.15c.825 0 1.495.668 1.495 1.49V74.5c0 .822-.67 1.49-1.495 1.49H13.448c-.825 0-1.494-.668-1.494-1.49V53.64c0-.822.67-1.49 1.494-1.49h41.84zm47.816 0c.825 0 1.495.668 1.495 1.49V74.5c0 .822-.67 1.49-1.495 1.49H61.264c-.824 0-1.494-.668-1.494-1.49V53.64c0-.822.67-1.49 1.494-1.49h41.84zm47.817 0c.824 0 1.494.668 1.494 1.49V74.5c0 .822-.67 1.49-1.494 1.49h-41.84c-.824 0-1.494-.668-1.494-1.49V53.64c0-.822.67-1.49 1.494-1.49h41.84z" transform="translate(46.816 28.31)" />
        <path fill="#FFF" fillRule="nonzero" d="M40.345 65.56H28.39c-.825 0-1.494-.668-1.494-1.49s.669-1.49 1.494-1.49h11.954c.825 0 1.494.668 1.494 1.49s-.67 1.49-1.494 1.49zM88.16 65.56H76.208c-.825 0-1.494-.668-1.494-1.49s.67-1.49 1.494-1.49H88.16c.825 0 1.494.668 1.494 1.49s-.67 1.49-1.494 1.49zM135.977 65.56h-11.954c-.825 0-1.494-.668-1.494-1.49s.67-1.49 1.494-1.49h11.954c.825 0 1.494.668 1.494 1.49s-.67 1.49-1.494 1.49z" opacity=".3" transform="translate(46.816 28.31)" />
      </g>
      <g>
        <path fill="#2979FF" d="M166.011 33.145L160.184 43.21 171.839 43.21zM4.528 149L8.988 141.297.069 141.297z" transform="translate(70.724)" />
        <path fill="#292A2E" fillRule="nonzero" d="M26.897 8.322L31.379 0.58 22.414 0.58z" transform="translate(70.724)" />
      </g>
      <g fillRule="nonzero" transform="translate(173.828 95.36)">
        <ellipse cx="23.161" cy="23.095" fill="url(#icon-no-bets-i)" rx="23.161" ry="23.095" />
        <path fill="#000" d="M21.83 26.848c-.754-.265-1.489-.533-2.216-.797-2.438 8.045 2.04 15.01 6.323 19.486.12.127.193.29.21.462-.598.085-1.2.134-1.804.167-4.465-4.826-8.876-12.142-6.234-20.67-1.777-.677-3.467-1.35-5.072-2.017-2.335 8.477-2.132 16.337.665 19.89.187.218.244.517.153.789-.008.023-.018.044-.032.065-.748-.323-1.479-.688-2.187-1.092-2.384-4.363-2.4-12.012-.08-20.276-1.645-.715-3.16-1.41-4.518-2.074l-.003.007c-1.173 3.387-3.1 6.466-5.64 9.002-.094.096-.212.166-.342.2-.171-.542-.32-1.1-.452-1.662 2.204-2.307 3.885-5.059 4.928-8.07l.071-.203c-1.7-.827-3.305-1.837-4.784-3.012l.493-1.608c.128.033.245.101.338.195 1.34 1.146 2.828 2.108 4.424 2.86 1.009-3.626 1.042-7.454.097-11.098.398-.426.804-.832 1.227-1.214.091.095.157.211.194.338 1.173 4.16 1.15 8.566-.068 12.714 1.309.656 2.816 1.348 4.488 2.08.204-.647.417-1.298.647-1.943C16.021 9.877 21.54 2.479 26.766.285c.794.119 1.58.286 2.354.5-.067.29-.296.514-.586.58-5.02 1.211-10.933 8.833-14.373 18.534-.242.684-.466 1.365-.674 2.043 1.587.66 3.307 1.351 5.154 2.044 4.78-11.762 19.374-12.598 24.541-12.5.289.5.558 1.008.816 1.522-.12.062-.257.09-.393.08-5.648-.174-19.044.602-23.477 11.457.727.265 1.47.535 2.23.803 11.266 3.97 19.713 5.732 22.238 5.422.131-.015.264.006.385.06-.19.533-.4 1.057-.632 1.567-3.589.17-12.481-2.01-22.52-5.549z" opacity=".65" />
      </g>
      <g fillRule="nonzero" transform="translate(142.448 13.41)">
        <path fill="url(#icon-no-bets-j)" d="M20.35 21.082c-1.488 2.615-7.16 2.21-12.668-.907C2.174 17.058-1.085 12.412.403 9.797l2.695-4.735 9.974 5.642 9.974 5.643-2.696 4.735z" />
        <ellipse cx="13.074" cy="10.698" fill="#1A1A1E" rx="5.449" ry="11.459" transform="rotate(-60.431 13.074 10.698)" />
        <path fill="url(#icon-no-bets-k)" d="M23.044 16.347c-1.488 2.615-7.16 2.21-12.668-.908C4.868 12.322 1.61 7.676 3.098 5.062c1.488-2.615 7.16-2.21 12.668.907 5.508 3.117 8.767 7.763 7.278 10.378z" />
      </g>
      <g transform="translate(7.966 11.92)">
        <ellipse cx="2.241" cy="39.485" fill="#2979FF" rx="2.241" ry="2.235" />
        <ellipse cx="129.253" cy="116.965" fill="url(#icon-no-bets-l)" fillRule="nonzero" rx="3.736" ry="3.725" />
        <ellipse cx="192.759" cy="2.98" fill="url(#icon-no-bets-m)" fillRule="nonzero" rx="2.989" ry="2.98" />
      </g>
    </g>
  </svg>
);
